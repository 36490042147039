import React from "react";
import {Layout} from "../Layout";
import ProductBanner from "../product_banners/product_banners";
import Banner from "../../imgs/bg_investment_ideas.jpg";

const InvestmentIdeasLayout = ({pageData, children}) => {
    const page = (
        <>
            <ProductBanner
                headingType="h1"
                bannerContainerClassName="bannerInvestment"
                bannerTrasparecy="bannerMask"
                productTitle="Investment Ideas"
                bannerImage={Banner}
                bannerImageClassName="cedearsBannerImage"
                textContainerClassName="textContainer"
                bannerText={null}
                bannerTextClassName="bannerText1"
                bannerImageMobile={Banner}
                mobile={false}
            />

            {children}

        </>
    )
    return (
        <main>
            <Layout title={pageData.title} description={pageData.description} childrem={page}
                    category={"investment_ideas"}></Layout>
        </main>
    )
}


export default InvestmentIdeasLayout;