import { graphql } from "gatsby";
import React from "react";
import InvestmentIdeasLayout from "../../components/investment-ideas/InvestmentIdeasLayout";
import InvestmentIdeas from "../../components/investment-ideas/InvestmentIdeas";

const pageData = {
    title: 'Mirá nuestras Investment Ideas y empezá a invertir | Balanz',
    description: 'Nuestros especialistas crean constantemente Investment Ideas. Conocé donde están las oportunidades de inversión y todas nuestras recomendaciones.',
    bannerText: `Siguiendo nuestro lema, <span class='highlightsbold'>Understand mankind, to Understand investment,</span> creamos el equipo de Investment Ideas.`
};

const BalanzUniversityPage = ({data}) => {
    return <InvestmentIdeasLayout pageData={pageData}>
        <InvestmentIdeas artdata={data.allInvestmentArticles.edges} />
    </InvestmentIdeasLayout>
}

export const query = graphql`
    query {
        allInvestmentArticles {
            edges {
                node {
                    slug
                    title
                    description
                    htmldesc
                    category
                    date
                    artid
                    imgUrl
                    section
                    highlighted
                    timestamp
                    order
                }
            }
        }
    }
`
export default BalanzUniversityPage