import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import {graphql, Link, useStaticQuery} from "gatsby";
import News from "../news/news";
import { InvestmentNews } from "../investment_news/investmentNews";
import Button from '../buttons/button'
import { ButtonList } from "../buttons_list/buttonList";
import { FaChevronRight } from "react-icons/fa";
import SlickVideoSlider from "../slick_video_slider/skick_video_slider";
import Banner from "../../imgs/bg_investment_ideas.jpg";
import Image from "../image/image";
import { SalWrapper } from "react-sal";
import { apiRequest } from "../../apiRquest/apiRequest";
import PackCedears from "../../imgs/banners-ballanz-03.jpg";
import '../../pages/investment-ideas.css'


const InvestmentIdeas = ({ artdata }) => {
    const [latest, setLatest] = useState([]);
    let tempArts = [...artdata]
    // console.log('tempArts', tempArts)
    tempArts.sort((a, b) => { return a.node.order - b.node.order } );
    let articulos2query = tempArts.slice(1,4)

    const slickSettings = {
        centerMode: false,
        centerPadding: "0px",
          initialSlide: 0,
          slidesToShow: 4,
        dots: false,
        arrows: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 992,
                settings: { centerMode: false, slidesToShow: 3, slidesToScroll: 3, infinite: false }
            },
            {
                breakpoint: 576,
                settings: {  centerMode: false, slidesToShow: 2, slidesToScroll: 2, infinite: false }
            }
          ]
    };

    let buttons = [
        /*{ title: "5G", url: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IlBBQ0stNUcgfCBQZXNvcyBDRURFQVIiLCJwYW5lbCI6IjM4IiwicGxhem8iOiIyIiwiaWRQbGF6byI6IjIifX0" },*/
        { title: "CEDEARs de ETFs", url: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IlBBQ0stIEVURiB8IFBlc29zIENFREVBUiIsInBhbmVsIjoiMzgiLCJwbGF6byI6IjIiLCJpZFBsYXpvIjoiMiJ9fQ\n"},
        { title: "Inteligencia Artificial", url: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IlBBQ0stSUEgfCBQZXNvcyBDRURFQVIiLCJwYW5lbCI6IjM4IiwicGxhem8iOiIyIiwiaWRQbGF6byI6IjIifX0" },
        { title: "Commodities", url: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IkNvbW1vZGl0aWVzIHwgUGVzb3MgQ0VERUFSIiwicGFuZWwiOiIzOCIsInBsYXpvIjoiMiIsImlkUGxhem8iOiIyIn19" },
        { title: "Acciones Value", url: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IlBBQ0stVkFMVUUgfCBQZXNvcyAgQ0VERUFSIiwicGFuZWwiOiIzOCIsInBsYXpvIjoiMiIsImlkUGxhem8iOiIyIn19" },
        { title: "Vehículos Autónomos", url: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IlBBQ0stVkEgfCBQZXNvcyBDRURFQVIiLCJwYW5lbCI6IjM4IiwicGxhem8iOiIyIiwiaWRQbGF6byI6IjIifX0" },
        { title: "5G", url: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IlBBQ0stNUcgfCBQZXNvcyBDRURFQVIiLCJwYW5lbCI6IjM4IiwicGxhem8iOiIyIiwiaWRQbGF6byI6IjIifX0" },
        { title: "Servicios Financieros", url: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IlBBQ0stRklOQU5DRSB8IFBlc29zIENFREVBUiIsInBhbmVsIjoiMzgiLCJwbGF6byI6IjIiLCJpZFBsYXpvIjoiMiJ9fQ" },
    ];

    const [articlesData, setaArticlesData] = useState(artdata);

    let articles = []

    articlesData.forEach((element) => {
        articles.push(element.node);
    });

    articles.sort((a, b) => {
        return b.order - a.order
    });

    let tpnews = articles.filter((r, i) => r.highlighted === 1);
    tpnews.sort((a, b) => { return a.order - b.order } );
    const [topNews, setTopNews] = useState(tpnews);


    const [investmentNews, setInvestmentNews] = useState(articles.slice(0, 2));

    useEffect(() => {
        apiRequest.get('/v1/investment')
            .then(response => {
                const resp = response.data.filter((r, i) => r.highlighted !== 1);
                const data  = resp.sort((a, b) => {
                    return b.order - a.order
                }).slice(0, 2);
                setInvestmentNews(data);
            });
    }, []);

    useEffect(() => {

        apiRequest
            .get("/v1/investment/highlighted")
            .then((res) => {
                setTopNews(res.data)
            })
            .catch((err) => {
                console.log(err);
            })


        apiRequest
            .get("/v1/latest")
            .then((res) => {
                const data = [];
                res.data.data.forEach((element) => {
                    data.push({
                        url: element.link,
                        title: element.title
                    })
                })
                setLatest(data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setLatest]);

    function getReadingTime(articles) {
        let words3 = JSON.stringify(articlesData)
        var words, time, minutes, seconds;
        words = words3.split(' ').length;
        time = (60 * words / 250)
        minutes = ~~(time / 60);
        seconds = Math.ceil(time - minutes * 60);
        if (seconds >= 30) {
            return `${minutes + 1}m`
        } else if (seconds < 30) {
            return `${minutes}m`
        }
    }

    return <SalWrapper>
        <section className="fullwidth page-description"
                data-sal="fade"
                data-sal-delay="0"
                data-sal-duration="200"
        >
            <Container>
                <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                    <Col xs={12} >
                        <p className="intro-text base-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                            Es un equipo de especialistas dedicado a recorrer permanentemente los
                            mejores instrumentos de inversión del mundo y las tendencias globales.<br/>
                            A través de un análisis exhaustivo, realizan reviews y recomendaciones
                            por instrumento y por sector.
                        </p>

                        <p className="base-text mt-4 mt-md-5 mb-0 py-2 py-sm-3 py-md-4 ">
                            <strong className="bigger"><Link to='/investment-ideas/articulos' className='secondary-color'>Lee nuestras Investment Ideas,</Link> para que invertir, te resulte aún más fácil.</strong>
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="fullwidthbg cedearspack">
            <Container>
                <Row className="justify-content-between">
                    <Col xs={12} md={8} lg={7} className="column col-text order-2 order-md-1">
                        <div className="section-content pt-md-5 pt-2"
                            data-sal="slide-up"
                            data-sal-delay="0"
                            data-sal-duration="100"
                        >
                            <p className="volanta">Packs de Cedears</p>
                            <h2 className="titulo pe-0 pe-sm-5 pe-md-4 pe-lg-3 pe-xl-2 pe-xxl-0 my-3">
                                <span className="block-quote"> Nuestros especialistas armaron packs de CEDEARS para que puedas invertir como ellos.</span>
                            </h2>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="column col-img order-1 order-md-2 pb-5">
                        <div className="section-content"
                            data-sal="slide-up"
                            data-sal-delay="100"
                            data-sal-duration="100"
                        >
                            <img
                                className="section-image lift ratio ratio-1x1"
                                src={PackCedears}
                                alt="Packs CEDEARs"
                            />
                        </div>
                    </Col>
                    <Col xs={12} md={8} lg={7} className="order-3 d-flex ">
                        <div className="section-content"
                            data-sal="slide-up"
                            data-sal-delay="0"
                            data-sal-duration="100"
                        >
                            <p className="texto mt-4 mb-4 mb-lg-5">
                                Se trata de una selección especial de compañías líderes que cotizan
                                    en Estados Unidos. Invertí en pesos en las carteras recomendadas por
                                    nuestros expertos.</p>
                            <p className="texto mb-4 mb-lg-5">Contamos con una amplia variedad de
                                    packs, curados y agrupados según diversas temáticas.
                            </p>
                            <div className="d-none d-md-block">
                            <p className="texto lt-font">Conocé todos los packs, ingresando a tu cuenta y <br className="d-none d-lg-inline" />comenzá a invertir.</p>
                            <div className="cta py-3">
                                <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                            </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="column order-4">
                        <div className="section-content mt-xl-4 mt-md-0 mb-md-0 mt-4 mb-5"
                            data-sal="slide-up"
                            data-sal-delay="0"
                            data-sal-duration="100"
                        >
                            <ButtonList buttonList={buttons} />
                        </div>
                    </Col>
                    <Col xs={12} md={7}  className="column d-flex d-md-none order-5">
                        <div className="section-content">
                            <p className="texto lt-font">Conocé todos los packs, ingresando a tu cuenta y comenzá a invertir.</p>
                            <div className="cta py-3 mb-4">
                                <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                            </div>
                        </div>
                    </Col>

                </Row>

            </Container>
        </section>
        <section className="fullwidth top-news">
            <Container>
                <Row className="justify-content-center g-3 g-md-3">
                    <h2>Top News</h2>
                    <News news={topNews} />
                {/* <div className="highlightbold text-end secondary-color m4">
                        Ver todas <FaChevronRight />{" "}
                    </div>*/}
                </Row>
            </Container>
        </section>
        <section className="fullwidth investment-news">
            <Container>
                <Row className="justify-content-center g-3 g-md-3 d-flex">
                    <Col xs={12} md={8} className="order-1">
                    <h2 className="order-1">Investment News</h2>
                    </Col>
                    <InvestmentNews
                        containerClass="investmentNewsContainer order-2 order-md-3 noborder"
                        investmentNews={investmentNews}
                        basePath="investment-ideas"
                        getReadingTime={getReadingTime}
                    />
                    <Col xs={12} md={4} className="d-flex align-items-center flex-row justify-content-end order-3 order-md-2 ">
                        <Link to="/investment-ideas/articulos">
                            <div className="highlightbold base-text text-end secondary-color m4 flex-grow-1">
                                Ver todas <FaChevronRight />{" "}
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="fullwidth latest-events"
                data-sal="slide-up"
                data-sal-delay="0"
                data-sal-duration="200"
        >
            <Container>
                <Row className="justify-content-center g-3 g-md-3">
                    <h3 className="ash2">Latest Events</h3>
                    <SlickVideoSlider linkList={latest} slickSettings={slickSettings} />
                    <div className="text-center py-3 py-sm-4 py-md-5 m-0"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="200">
                        <Button variant="secondary" text="Accedé a nuestro canal de YouTube" onClick={() => { window.open("https://www.youtube.com/channel/UCl7iljPb3wACm-lOyqM1dHA", "_blank") }} />
                    </div>
                </Row>
            </Container>
        </section>
    </SalWrapper>
}

export default InvestmentIdeas;