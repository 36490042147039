import React from "react";
import { Button } from "react-bootstrap";
import "../buttons_list/buttonList.css";

export const ButtonList = ({ buttonList }) => {
  return (
    <div className="buttons-container">
      {buttonList.map((button, key) => {
        return (
          <Button
            key={key}
            className="button-list-item"
            onClick={() => { window.open(`${button.url}`, "_blank") }}
          >
            {button.title}
          </Button>
        );
      })}
    </div>
  );
};
